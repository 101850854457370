<template>
  <!--begin::Product Internal Listing-->
  <v-container fluid>
    <ListingTable
      :column-count="columnCount"
      :data-loading="dataLoading"
      :row-data="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th">Warranty Info</th>
            <th class="simple-table-th" v-if="false">Customer Info</th>
            <th class="simple-table-th" v-if="false">Site Location</th>
            <th class="simple-table-th">Warranty Dates</th>
            <th class="simple-table-th">Warranty Info</th>
            <th class="simple-table-th">Status</th>
            <th class="simple-table-th">Created Time</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows.length">
            <template v-for="(data, index) in dataRows">
              <v-hover :key="index" v-slot="{ hover }">
                <tr
                  class="alternate-listing-row"
                  :key="index"
                  v-on:click="detailWarranty(data?.id)"
                >
                  <td>
                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>Serial No: </b>
                      <Barcode
                        class="ml-1"
                        small
                        :barcode="data.unique_id"
                      ></Barcode>
                    </p>
                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>Cost : </b>
                      <template v-if="data && data.cost">
                        <v-chip
                          label
                          small
                          class="font-weight-600 custom-grey-border text-uppercase ml-9"
                          text-color="white"
                          color="blue"
                        >
                          {{ formatMoney(data.cost) }}
                        </v-chip>
                      </template>
                      <template v-else
                        ><em class="text--secondary">No cost</em></template
                      >
                    </p>
                  </td>
                  <td v-if="false">
                    <template
                      v-if="
                        data &&
                        data.equipment &&
                        data.equipment.main_type == 'main_customer'
                      "
                    >
                      <div class="warranty-listing-product">
                        <p class="m-0 custom-nowrap-ellipsis">
                          <b>Display Name: </b>
                          <template
                            v-if="data.customer && data.customer.display_name"
                            >{{ data.customer.display_name }}</template
                          >
                          <template v-else
                            ><em class="text--secondary"
                              >No Display Name</em
                            ></template
                          >
                        </p>
                        <p class="m-0 custom-nowrap-ellipsis">
                          <b>Company: </b>
                          <template
                            v-if="data.customer && data.customer.company_name"
                            >{{ data.customer.company_name }}</template
                          >
                          <template v-else
                            ><em class="text--secondary"
                              >No Company</em
                            ></template
                          >
                        </p>
                        <p class="m-0 custom-nowrap-ellipsis">
                          <b>Email: </b>
                          <template
                            v-if="data.customer && data.customer.company_email"
                            >{{ data.customer.company_email }}</template
                          >
                          <template v-else
                            ><em class="text--secondary">No Email</em></template
                          >
                        </p>
                        <p class="m-0 custom-nowrap-ellipsis">
                          <b>Phone: </b>
                          <template
                            v-if="data.customer && data.customer.company_number"
                            >{{ data.customer.company_number }}</template
                          >
                          <template v-else
                            ><em class="text--secondary">No Phone</em></template
                          >
                        </p>
                      </div>
                    </template>
                    <template v-else>
                      <v-chip
                        label
                        color="#0d47a1"
                        outlined
                        small
                        class="p-3 mr-5"
                      >
                        Own Asset
                      </v-chip>
                    </template>
                  </td>
                  <td v-if="false">
                    <div class="property-listing-customer">
                      <p
                        class="m-0 custom-nowrap-ellipsis-four-line word-break"
                        v-if="data?.property"
                      >
                        {{ data?.property?.street_1 }}
                        {{ data?.property?.street_2 }}
                        <br />
                        {{ data?.property?.unit_no }}
                        {{ data?.property?.country }}
                        <template v-if="data?.property?.zip_code != '000000'">
                          , {{ data?.property?.zip_code }}
                        </template>
                      </p>
                    </div>
                  </td>
                  <td>
                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>Start: </b>{{ formatDate(data.start_date) }}
                    </p>
                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>End: </b>{{ formatDate(data.end_date) }}
                    </p>
                  </td>
                  <td>
                    <p class="m-0 custom-nowrap-ellipsis">
                      <template v-if="data.end_date">
                        <b
                          >Expiring on
                          {{ formatDate(data.end_date) }}
                          midnight.</b
                        >
                      </template>
                    </p>
                    <p class="m-0 custom-nowrap-ellipsis">
                      <template v-if="data.end_date && totalDays(data) > 0">
                        <b>Remaining :</b>
                        {{ remaningDate(data) }}
                        <span style="color: red; font-weight: 700">
                          ({{ totalDays(data) }} Days)</span
                        >
                      </template>
                    </p>
                  </td>
                  <td>
                    <div class="project-listing-status">
                      <CustomStatus
                        small
                        :status="data.status"
                        endpoint="warranty/status"
                      ></CustomStatus>
                    </div>
                    <v-chip
                      v-if="
                        data.extended_id && data.is_extended && data.extended
                      "
                      label
                      small
                      outlined
                      class="font-weight-600 custom-grey-border text-uppercase ml-2"
                      text-color=""
                      color="red"
                    >
                      {{ data.extended.unique_id }}
                    </v-chip>
                  </td>
                  <td class="simple-table-td">
                    <TableActivity
                      v-if="!lodash.isEmpty(data?.added_by)"
                      :data="data"
                    >
                      <template v-slot:display_name>
                        {{ data?.added_by.display_name }}
                      </template>
                      <template v-slot:date_time>
                        {{ data?.created_at }}
                      </template>
                      <template v-slot:format_date_time>
                        {{ formatDate(data?.added_at) }}
                        {{ formatDateSartTime(data?.added_at) }}
                      </template>
                    </TableActivity>
                  </td>
                </tr>
              </v-hover>
            </template>
          </template>
          <tr v-else>
            <td :colspan="columnCount">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no warranties at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
    <ListingFooter
      :dataLoading="dataLoading"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :totalPages="totalPages"
    ></ListingFooter>
  </v-container>
  <!--end::Product Internal Listing-->
</template>

<script>
import {
  QUERY /* , GET, POST, DELETE  */,
} from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
import { toSafeInteger } from "lodash";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import moment from "moment";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";

export default {
  mixins: [CommonMixin, FileManagerMixin, ValidationMixin],
  name: "equipment-internal-list",
  data() {
    return {
      parent: 0,
      dates: [],
      statusList: [],
      warrantyDurationTypes: [
        { text: "Month(s)", value: "month" },
        { text: "Year(s)", value: "year" },
      ],
      dataRows: [],
      totalPages: 0,
      paginationPageSize: 10,
      currentPage: 1,
      totalRows: 0,
      rowsOffset: 0,
      dataLoading: true,
      manage_brand: false,
      manage_location: false,
      form_loading: false,
      image_loading: false,
      filter: {
        status: "all",
        search: null,
      },
      timeout: null,
      EquipmentDialog: false,
      timeoutLimit: 500,
      delete_dailog: false,
      delete_loading: false,
      delete_row: {},
      new_equipment_dailog: false,
      all_customers: [],
      all_property: [],
      brands: [],
      equipment_locations: [],
      filtered_property: [],
      equipments: [],
      equipment: {
        id: null,
        uuid: null,
        image: null,
        customer: null,
        property: null,
        name: null,
        serial_no: null,
        model: null,
        brand: null,
        location: null,
        warranty: 0,
        warranty_duration: null,
        warranty_type: null,
        description: "",
      },
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "customer",
    },
    isCustomer: {
      type: Boolean,
      default: false,
    },
    equipmentId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    // equipmentId(param) {
    //   this.equipment.customer = param;
    // },
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        if (this.lodash.isEmpty(param) === false) {
          this.parent = this.lodash.toSafeInteger(param.id);
          if (this.parent > 0) {
            this.get_all_warranty();
          }
        }
      },
    },
  },
  components: {
    CustomStatus,
    TableActivity,
    ListingFooter,
    ListingTable,
    Barcode,
  },
  methods: {
    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    formatDateSartTime(row) {
      return moment(row).format("hh:mm A");
    },
    detailWarranty(param) {
      this.$router.push(
        this.getDefaultRoute("warranty.detail", {
          params: {
            id: param,
          },
        })
      );
    },
    remaningDate(date) {
      var a = moment(date.end_date);
      //var b = moment(date.start_date);
      //console.log(b);
      var b = moment().format("YYYY-MM-DD");
      b = moment(b);
      var years = a.diff(b, "year");
      b.add(years, "years");

      var months = a.diff(b, "months");
      b.add(months, "months");

      var days = a.diff(b, "days");
      if (days <= 0) {
        days = "";
      } else {
        days = days + " Days";
      }
      if (months <= 0) {
        months = "";
      } else {
        months = months + " Months ";
      }
      if (years <= 0) {
        years = "";
      } else {
        years = years + " Years ";
      }
      return years + months + days;
    },
    totalDays(date) {
      let today = moment().format("YYYY-MM-DD");

      var a = moment(date.end_date);
      var b = moment(today); //date.start_date
      var final = a.diff(b, "days"); // =1
      if (final <= 0) {
        final = 0;
      }
      return final;
    },
    getStatusText(data) {
      if (toSafeInteger(data) == 1) {
        return "Draft";
      } else if (toSafeInteger(data) == 2) {
        return "Active";
      } else if (toSafeInteger(data) == 3) {
        return "Extended";
      } else if (toSafeInteger(data) == 4) {
        return "Terminated";
      } else if (toSafeInteger(data) == 5) {
        return "Expired";
      }
    },
    getStatusColor(data) {
      if (toSafeInteger(data) == 1) {
        return "blue";
      } else if (toSafeInteger(data) == 2) {
        return "green";
      } else if (toSafeInteger(data) == 3) {
        return "orange";
      } else if (toSafeInteger(data) == 4) {
        return "red";
      } else if (toSafeInteger(data) == 5) {
        return "teal";
      }
    },
    detailWarrnty(param) {
      this.$router.push(
        this.getDefaultRoute("warranty.detail", {
          params: {
            id: param,
          },
        })
      );
    },

    get_formatted_property(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }
      if (row.zip_code) {
        tempArr.push(row.zip_code);
      }
      if (row.country_name) {
        tempArr.push(row.country_name);
      }
      return tempArr.join(", ");
    },

    get_all_warranty() {
      const _this = this;

      let filter = {
        status: "all",
        per_page: _this.paginationPageSize,
        current_page: _this.currentPage,
        search: _this.filter.search || undefined,
        id: _this.equipmentId,
        // dates: _this.dates || undefined,
        // type: _this.type,
        //type_id: _this.parent,
      };

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "equipment/warranty",
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.rows;
            _this.totalPages = Math.ceil(data.total_rows / data.per_page);
            _this.currentPage = data.current_page;
            _this.totalRows = data.total_rows;
            _this.rowsOffset = data.offset;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
  mounted() {
    const _this = this;

    PaginationEventBus.$on("update:pagination", (param) => {
      _this.currentPage = param;
      _this.get_all_warranty();
    });

    _this.$nextTick(() => {
      _this.equipment.customer = _this.equipmentId;
    });
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 70);
    },
    columnCount() {
      let result = 9;
      if (this.isCustomer) {
        --result;
      }
      return result;
    },
    showingFrom() {
      return this.lodash.isEmpty(this.dataRows) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.dataRows ? this.dataRows.length : 0);
    },
  },
};
</script>
